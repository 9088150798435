const dictionary = {
  hero: {
    title: "Hybrid Logistics Solutions",
    subtitle:
      "Asset-Based Carrier & Tech-Enabled Freight Brokerage serving the Midwest since 2019",
    shipNow: "Ship Now",
    joinNetwork: "Join Our Network",
  },
  about: {
    heading: "Complete Logistics Solutions",
    body: "Since 2019, we've successfully moved thousands of loads for our valued customers, maintaining a 98.5% on-time delivery rate. Our commitment to excellence has earned us long-term partnerships with leading manufacturers and distributors across the Midwest.",
  },
  services: {
    forShippers: {
      title: "For Shippers",
      description: "Service, responsiveness and consistent results",
      features: [
        "Dedicated capacity",
        "Real-time tracking",
        "Analytics dashboard",
        "24/7 support",
      ],
      buttonText: "Learn More",
      buttonLink: "/shippers",
    },
    forCarriers: {
      title: "For Carriers",
      description: "The support you need to be successful",
      features: [
        "Consistent freight",
        "Quick pay options",
        "Digital load booking",
        "Dedicated support",
      ],
      buttonText: "Learn More",
      buttonLink: "/carriers",
    },
  },
  cta: {
    main: "Ready to Streamline. Connect. Deliver?",
    body: "Experience the power of hybrid logistics solutions",
    requestQuote: "Request a Quote",
    contactSales: "Contact Sales",
  },
  carrierNetwork: {
    main: "Carrier Network",
    description:
      "Our carrier network is powered by freightdok capacity network, providing cutting-edge technology and seamless operations",
    statBoxes: {
      digitalBooking: {
        title: "Digital Booking",
        description: "Instant load booking",
      },
      realTimeUpdates: {
        title: "Real-Time Updates",
        description: "Automated notifications",
      },
      fastPay: {
        title: "Fast Pay",
        description: "Quick payment options",
      },
      support: {
        title: "24/7 Support",
        description: "Always available",
      },
    },
  },

  // carriers

  carrierhero: {
    heading: "Carriers",
    mainHeading: "Driven \n for you.",
    description:
      "Join our growing network of successful carriers. We provide consistent freight opportunities and the support you need to grow your business.",
    button: "Sign up as a carrier",
  },
  carrierstats: {
    heading: "Let Sunny Logistics work for you.",
    description:
      "Founded in 2019, we built our company on the principle of treating carriers with fairness and transparency. Our extensive customer base in the Midwest provides consistent freight opportunities year-round.",

    stats: [
      { title: "10,000+", description: "Loads Delivered" },
      { title: "1:1", description: "Single Point of Contact" },
      { title: "100+", description: "Active Shippers" },
      { title: "24/7", description: "Support" },
    ],
  },
  benefits: {
    heading: "Why Partner With Us",
    features: [
      {
        icon: "DollarSign",
        title: "Competitive Rates",
        description:
          "Earn more with our competitive rates and quick pay options available within 24 hours",
      },
      {
        icon: "BarChart",
        title: "Consistent Freight",
        description:
          "Access steady, year-round opportunities from our established Midwest customer base",
      },
      {
        icon: "Users",
        title: "Dedicated Support",
        description:
          "Work with a dedicated team that understands your business needs",
      },
    ],
  },
  network: {
    heading: "Carrier Network",
    description:
      "Our carrier network is powered by freightdok capacity network, providing you with cutting-edge technology and seamless operations.",
    requirements: [
      "Real-time load matching and booking",
      "Automated document processing",
      "Quick and easy settlement access",
      "Mobile app for on-the-go management",
    ],
  },
  requirements: {
    heading: "Partner Requirements",
    cards: [
      {
        title: "Insurance Coverage",
        features: ["$100,000 Cargo Insurance", "$1,000,000 Auto Liability"],
      },
      {
        title: "Compliance",
        features: [
          "Valid Operating Authority",
          "Satisfactory Safety Rating",
          "ELD Compliance",
        ],
      },
      {
        title: "Documentation",
        features: ["W-9 Form", "Carrier Agreement", "Direct Deposit Info"],
      },
    ],
  },
  carrierfooter: {
    title: "Ready to grow with us?",
    description:
      "Join our carrier network today and access consistent, profitable freight opportunities across the Midwest.",
    buttonOne: "Apply Now",
    buttonTwo: "Contact Us",
  },

  // about

  aboutheroSection: {
    mainHeading: "Building the\nNext Freight\nEcosystem",
    subHeading:
      "Since 2019, we've been transforming Midwest logistics through innovation,\ntechnology, and an unwavering commitment to excellence.",
  },
  missionVision: {
    missionHeading: "Our Mission",
    missionText:
      "To create a connected freight ecosystem driven by data, automation, and service. We aim to build a healthy and stable network for shippers and carriers in the Midwest, fostering growth and efficiency through technology and transparency.",
    visionHeading: "Our Vision",
    visionText:
      "To become the leading technology-enabled logistics provider in the Midwest, setting new standards for reliability, innovation, and customer service in the transportation industry.",
  },
  coreValues: {
    heading: "Our Core Values",
    values: [
      {
        title: "Trust",
        description:
          "Building lasting relationships through reliability and consistent performance",
      },
      {
        title: "Transparency",
        description:
          "Maintaining open communication and visibility in all our operations",
      },
      {
        title: "Fairness",
        description:
          "Treating all partners with respect and ensuring mutual success",
      },
    ],
  },
  timeline: [
    {
      year: "2019",
      title: "Foundation",
      description:
        "Started with a vision to revolutionize Midwest logistics. Founded by industry veterans who saw the need for a more innovative, technology-driven approach to transportation. Began operations with a single truck and a commitment to exceptional service.",
    },
    {
      year: "2020",
      title: "Sunny Freight Launch",
      description:
        "Established our trucking division to provide dedicated capacity solutions. Expanded our fleet to serve key Midwest lanes and built our initial customer base through a focus on reliability and personal service. Implemented our first digital tracking solutions.",
    },
    {
      year: "2021",
      title: "Technology Integration & Growth",
      description:
        "Partnered with freightdok to enhance our digital capabilities. Launched our automated booking platform, real-time tracking system, and digital documentation solutions. Expanded our carrier network to over 50 trusted partners and achieved 5,000+ successful deliveries.",
    },
    {
      year: "2023",
      title: "Network & Technology Evolution",
      description:
        "Reached milestone of 10,000+ loads delivered and 100+ active customers. Enhanced our freightdok integration with new features for both carriers and shippers. Expanded our trailer pool program and implemented advanced route optimization algorithms.",
    },
  ],
  aboutfooter: {
    title: "Want to Learn More?",
    description:
      "Connect with our team to learn more about our services and how we can help optimize your supply chain.",
    buttonOne: "Contact Us",
    buttonTwo: "Join Our Team",
  },

  // shippers

  heroSection: {
    heading: "Shippers",
    mainHeading: "Streamline.\nConnect.\nDeliver.",
    bodyText:
      "Experience the power of hybrid logistics solutions combining asset-based reliability with tech-enabled flexibility.",
    button: "Get Started",
  },
  statsSection: {
    mainHeading: "Your Trusted Logistics Partner",
    bodyText:
      "Since 2019, we've been delivering excellence in freight transportation across the Midwest. Our hybrid model combines the reliability of asset-based operations with the flexibility of a tech-enabled brokerage.",
    stats: [
      { title: "10,000+", description: "Loads Delivered" },
      { title: "98.5%", description: "On-Time Delivery" },
      { title: "24/7", description: "Support" },
      { title: "40+", description: "Trucks" },
    ],
  },
  servicesSection: {
    mainHeading: "Complete Logistics Solutions",
    services: [
      {
        icon: "Truck",
        title: "Asset-Based Services",
        description: "Reliable capacity with our dedicated fleet",
        features: [
          "Dedicated equipment",
          "Professional company drivers",
          "Regular routes available",
          "Temperature controlled options",
        ],
      },
      {
        icon: "Network",
        title: "Brokerage Services",
        description: "Flexible solutions for any shipping need",
        features: [
          "Nationwide coverage",
          "Vetted carrier network",
          "Specialized equipment",
          "Surge capacity",
        ],
      },
    ],
  },
  technologyPlatform: {
    mainHeading: "Technology-Driven Solutions",
    bodyText:
      "Powered by freightdok, our platform provides real-time visibility and control over your shipments",
    features: [
      {
        icon: "Target",
        title: "Real-Time Tracking",
        description:
          "Monitor your shipments with GPS precision and receive proactive status updates",
      },
      {
        icon: "Box",
        title: "Digital Documentation",
        description:
          "Access BOLs, PODs, and invoices in one centralized platform",
      },
      {
        icon: "BarChart",
        title: "Analytics Dashboard",
        description:
          "Gain insights into your shipping patterns and optimize your supply chain",
      },
    ],
  },
  ourEdge: {
    mainHeading: "Our Edge",
    features: [
      {
        icon: "Shield",
        title: "Dedicated Service",
        description:
          "Single point of contact for all your shipping needs with 24/7 support",
      },
      {
        icon: "Clock",
        title: "Fast Response",
        description:
          "Quick quotes and immediate capacity solutions when you need them",
      },
      {
        icon: "Target",
        title: "Regional Expertise",
        description:
          "Specialized knowledge of Midwest freight lanes and requirements",
      },
    ],
  },
  footer: {
    title: "Ready to optimize your supply chain?",
    description:
      "Experience the difference of working with a logistics partner who understands your needs.",
    buttonOne: "Request a Quote",
    buttonTwo: "Contact Sales",
  },
};

export default dictionary;
